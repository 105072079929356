// #TODO: Try using the package, config. It's not used as the way to make it work with Next is unknown.
const config = {
  zendesk: {
    url: {
      agentTicketView: 'https://hafla4229.zendesk.com/agent/tickets/'
    }
  },
  isProduction: process.env.NEXT_PUBLIC_APP_ENV === 'production'
};

export default config;
